import { BillingPlan, CreditsPurchaseItemCode } from '@/features/billing';

const enabled = !!import.meta.env.VITE_GOOGLE_TAG_MANAGER_CONTAINER_ID;

export const trackSignup = (signupVariant: string) => {
  if (!enabled) return;
  window.dataLayer.push({
    event: 'signup',
    signup_variant: signupVariant,
  });
};

export const trackSignupPageVariant = (signupVariant: string) => {
  if (!enabled) return;
  window.dataLayer.push({
    signup_variant: signupVariant,
  });
};

export const trackVerifyEmail = () => {
  if (!enabled) return;
  window.dataLayer.push({ event: 'verify_email' });
};

export const trackLogin = () => {
  if (!enabled) return;
  window.dataLayer.push({ event: 'login' });
};

export const trackSwitchPlan = (plan: BillingPlan, total?: number) => {
  if (!enabled) return;

  const value = total != null ? Math.floor(total / 100) : undefined;

  window.dataLayer.push({
    event: 'switch_plan',
    billing_plan: plan,
    purchase_value: value,
  });
};

export const trackPurchaseCredits = (
  itemCode: CreditsPurchaseItemCode,
  total?: number
) => {
  if (!enabled) return;

  const value = total != null ? Math.floor(total / 100) : undefined;

  window.dataLayer.push({
    event: 'purchase_credits',
    code: itemCode,
    purchase_value: value,
  });
};
